<template>
  <div class="buylist-style">
    <!-- <div class="outbox">
      <el-card class="box-card">
        <div class="box1">{{$t('text.t196')}}</div>
        <div class="box2 between-center">
          <div class="box2-item">{{$t('text.t197')}}</div>
          <div class="box2-item">{{$t('text.t103')}}</div>
          <div class="box2-item">{{$t('text.t198')}}</div>
          <div class="box2-item">{{$t('text.t118')}}</div>
        </div>
        <div>
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist"
          >
            <div class="box3 between-center" v-for="item in list" :key="item.id">
              <div class="box3-item break">{{item.title}}#{{item.tokenid}}</div>
              <div class="box3-item">{{item.number}}</div>
              <div class="box3-item break">{{item.total}}</div>
              <div class="box3-item">{{$parseTime(item.createtime)}}</div>
            </div>
          </van-list>
        </div>
      </el-card>
    </div> -->
    <titles :title="$t('text.t196')" />
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <div class="row1 between-center">
            <div class="start-center">
              <img :src="chain_list.find(ele=>ele.chain_type==item.chain)?.chain_logo" class="i1" />
              <div class="t1">{{chain_list.find(ele=>ele.chain_type==item.chain)?.name}}</div>
            </div>
            <div class="t2">{{ $parseTime(item.createtime) }}</div>
          </div>
          <div class="row2 between-center">
            <img :src="item.image" class="i2" />
            <div class="t3">{{ item.title }}#{{ item.tokenid }}</div>
            <div class="b1">
              <div class="t4">{{ item.total }} USDT</div>
              <div class="t5">*{{ item.number }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import titles from "@/components/titles/index.vue";
export default {
  components: {
    titles,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {
    ...mapState(["chain_list"]),
  },
  mounted() {},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
      };
      this.$http.get("/order/orderList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.buylist-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
        text-align: center;
      }
      .box2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 25%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 25%;
        }
      }
    }
  }
  .list {
    .item {
      padding: 15px;
      border-bottom: 1px solid #e1e1e1;
      .row1 {
        font-weight: bold;
        color: #323232;
        margin-bottom: 14px;
        .i1 {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }
        .t1 {
          font-size: 11px;
        }
        .t2 {
          font-size: 12px;
        }
      }
      .row2 {
        .i2 {
          width: 41px;
          height: auto;
          margin-right: 10px;
        }
        .t3 {
          flex: auto;
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b1 {
          margin-left: 10px;
          text-align: right;
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          .t4 {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>