<template>
  <van-sticky>
    <div class="titles-style between-center">
      <van-icon name="arrow-left" size="20" @click="goBack" />
      <div class="t1">{{ title }}</div>
      <van-icon name="arrow-left" size="20" style="opacity: 0" />
    </div>
  </van-sticky>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.titles-style {
  background-color: #fff;
  width: 100%;
  height: 44px;
  padding: 0 20px;
  box-sizing: border-box;
  .t1 {
    font-size: 15px;
    font-weight: bold;
    color: #323232;
  }
}
</style>